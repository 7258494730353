module services {
  export module tariff {
    export class hsCodeKeyWordService implements interfaces.tariff.IHsCodeKeyWordService
    {
      static $inject = ["$resource", "ENV"];

      constructor(
        private $resource: ng.resource.IResourceService,
        private ENV: interfaces.applicationcore.serverConfig
      ) {}

      getHsKeyWordList(): ng.resource.IResourceClass<interfaces.tariff.IHsCodeKeyWord> {
        return this.$resource<interfaces.tariff.IHsCodeKeyWord>(
          this.ENV.DSP_URL + "HSCodeKeyWords/GetHsKeyWordList",
          {
            hsCode: "@hsCode",
            DescriptionEnglishUK: "@DescriptionEnglishUK",
            DescriptionFrench: "@DescriptionFrench",
            DescriptionPortuguese: "@DescriptionPortuguese",
            DescriptionSpanish: "@DescriptionSpanish",
            isActive: "@isActive",
            numberRecords: "@numberRecords",
            pageNumber: "@pageNumber"
          },
          {
            query: {
              method: "GET",
              isArray: true,
              interceptor: {
                response: config.appResourceDateInterceptor.parseResponseDates,
              },
            },
          }
        );
      }

      saveAll(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
        return this.$resource<interfaces.applicationcore.IMessageHandler>(
          this.ENV.DSP_URL + "HSCodeKeyWords/SaveAll",
          {}
        );
      }

      removeHSCodeKeyWord(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
        return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "HSCodeKeyWords/RemoveHSCodeKeyWord", {
            hsCodeKeyWordId: '@hsCodeKeyWordId',
        });
    }

    }
  }
  angular.module("app").service("hsCodeKeyWordService", services.tariff.hsCodeKeyWordService);
}
